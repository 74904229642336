import React from "react";
import { getDateRange } from "../../../../payroll/utils/getDateRange";

function formatDate(date) {
  if (!date) return;
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${month.toString().padStart(2, "0")}/${day
    .toString()
    .padStart(2, "0")}`;
}

export default function TableBreakdown({
  payroll,
  startDate,
  endDate,
  holidays,
}) {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const type = ["HR", "OT", "ND"];

  const header = getDateRange(startDate, endDate)
    .slice(0, 7)
    .map((item) => {
      return {
        day: days[item.getDay()],
        date: item,
      };
    });
  header.unshift("");

  const getHoliday = (index) => {
    if (holidays.length < 1) return;
    const holiday = holidays.find(
      (holiday) =>
        new Date(holiday.date).getDate() === header[index].date.getDate() &&
        new Date(holiday.date).getMonth() === header[index].date.getMonth()
    );

    const isSunday = header[index].date.getDay() === 0;

    if (isSunday && holiday?.type.name.includes("Regular")) return "RD/REG";
    if (isSunday && holiday?.type.name.includes("Special")) return "RD/SPE";
    if (isSunday) return "RD";
    if (holiday?.type.name.includes("Regular")) return "REG";
    if (holiday?.type.name.includes("Special")) return "SPE";
    return null;
  };

  const getBackground = (value) => {
    const colors = {
      RD: "#f4af42",
      REG: "#4c6ae7",
      SPE: "#bf62e2",
      "RD/REG": "#9b8d95",
      "RD/SPE": "#d49162",
    };

    return colors[value] || null;
  };

  const total = [
    "Total",
    ...header
      .filter((item) => item !== "")
      .map((item) => {
        const total =
          payroll[`${item.day?.toLowerCase()}Total`]?.toFixed(2) || 0;
        return total;
      }),
  ];

  return (
    <div
      className="rounded position-relative m-0"
      style={{ boxShadow: "0 0 5px rgba(0, 0, 0, .1)" }}
    >
      <div
        className="d-flex p-3 m-0 rounded-top"
        style={{ background: "#e6e6e6" }}
      >
        {header.map((item, index) => (
          <p
            key={index}
            className="p-0 m-0 fw-semibold text-center"
            style={{ flex: "1", color: "#434343", fontSize: ".75rem" }}
          >
            {item !== "" &&
              `${item.day.slice(0, 3).toUpperCase()} ${formatDate(item.date)}`}
          </p>
        ))}
      </div>
      <div className="d-flex flex-column">
        {[[...Array(7)], [...Array(7)], [...Array(7)]].map((item, rowIndex) => (
          <div className="d-flex flex-row p-3 m-0" key={rowIndex}>
            <p
              className="p-0 m-0 fw-semibold text-center"
              style={{ flex: "1", color: "#434343", fontSize: ".75rem" }}
            >
              {type[rowIndex]}
            </p>
            {item.map((i, colIndex) => (
              <p
                key={colIndex}
                className={`p-0 m-0 fw-semibold text-center ${
                  payroll?.hasOwnProperty(
                    `${type[rowIndex]}${header[colIndex + 1]?.day}`
                  )
                    ? "opacity-100"
                    : "opacity-25"
                }`}
                style={{ flex: "1", color: "#434343", fontSize: ".75rem" }}
              >
                {payroll?.hasOwnProperty(
                  `${type[rowIndex]}${header[colIndex + 1]?.day}`
                )
                  ? payroll[`${type[rowIndex]}${header[colIndex + 1]?.day}`]
                  : 0}
              </p>
            ))}
          </div>
        ))}
      </div>
      <div className="d-flex p-3 m-0">
        <p
          className="p-0 m-0 fw-semibold text-center"
          style={{ flex: "1", color: "#434343", fontSize: ".75rem" }}
        >
          INC
        </p>
        {[...Array(7)].map((item, index) => (
          <div
            key={index}
            className="p-0 m-0 fw-semibold text-center d-flex justify-content-center"
            style={{
              flex: "1",
              color: "white",
              fontSize: ".75rem",
            }}
          >
            <p
              style={{
                width: "fit-content",
                paddingInline: ".8rem",
                paddingBlock: ".1rem",
                borderRadius: ".2rem",
                background: getBackground(getHoliday(index + 1)),
              }}
            >
              {getHoliday(index + 1)}
            </p>
          </div>
        ))}
      </div>
      <div
        className="d-flex p-3 m-0 rounded-bottom"
        style={{ background: "#DAB44E" }}
      >
        {total.map((item, index) => (
          <p
            key={index}
            className="p-0 m-0 fw-semibold text-center"
            style={{ flex: "1", color: "#434343", fontSize: ".75rem" }}
          >
            {`${item === "Total" ? "" : "₱"}${item}`}
          </p>
        ))}
      </div>
    </div>
  );
}
